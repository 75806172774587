<template>
  <div class="box">
    <div :class="acc ? 'slide-in-animation' : 'onslide-in-animation'" ref="slideInAnimation">
      <img src="https://www.gm.com/assets/%E5%8D%81%E5%9B%9B%E6%9C%9F-CKW22HE9.png" alt=""
           style="width:400px;height:200px;">
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  setup() {
    const slideInAnimation = ref(null);
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          acc.value = true
        }
      });
    });
    onMounted(() => {
      //监控元素，在调用ittersectionobserver的observe方法传入元素对象
      observer.observe(slideInAnimation.value);
    });
    const acc = ref(false)

    return {
      slideInAnimation, acc
    };
  }
});
</script>

<style scoped>
.box {
  overflow: hidden;
  height: 4000px;
  /* 为了演示效果，设置一个高度 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-in-animation,
.onslide-in-animation {
  //width: 400px;
  //height: 200px;

}

.slide-in-animation {
  background-color: #f00;
  position: relative;
  animation: slide-in 4s forwards;
}

@keyframes slide-in {
  0% {
    visibility: hidden;
    left: 100%;
  }

  100% {
    left: 0%;
  }
}
</style>