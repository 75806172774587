<template>
  <div class="ind1">

    <iframe :src="randomUrl" class="map"></iframe>
    <div class="addweixin">
      <div class="left">添加微信,获取资料及报价</div>
      <div class="right" @click="openweixinshow()">
        <img src="../assets/weixinb.png" alt="" class="wechatpng">
        <span class="wechat">添加微信</span>
      </div>
    </div>
    <div class="more" @click="switchMenu">
      <img src="../assets/gengduo.png" alt="" />
    </div>
  </div>
  <div class="index-about" id="show">
    <div class="index-aboutBox">
      <div class="indexabout-back">
       
        <img class="img" src="https://yun-bo.net/data/aboutback.png" alt="" />
      </div>
      <div class="indexTitleBox">
        <div class="indexTitle2">
          <h2 class="cn">{{ indexList.site_name }}</h2>
          <div class="en">
            Yunbo (Chongqing) Smart Agriculture Technology Co., Ltd
          </div>
          <div class="wz wow fadeInUp50 animated">
            <div>提供智慧农业一站式解决方案</div>
            <div>
              提供智慧农业一站式解决方案应用场景覆盖智慧农业、智慧大棚、智慧灌溉、智慧果园、智慧养殖、土壤改良等
            </div>
          </div>
        </div>
        <div class="MoreBtn">
          <span class="chakanname" @mouseenter.native="lookMore_mouseenter" @mouseout.native="lookMore_mouseout" @click="topage(1)">查看更多 </span>
          <span class="ArrowRightBold">
            <el-icon>
              <ArrowRightBold />
            </el-icon>
          </span>
        </div>
      </div>
      <div :class="accShow ? 'indexAboutBox' : 'indexAboutBox1'" ref="acc">
        <table width="100%" border="0" cellpadding="0" class="pageshuju ke-zeroborder">
          <tbody class="indexAboutBoxcontent">
            <tr>
              <td class="shu wow fadeIn animated">
                <div>
                  <countTo :startVal="0" :endVal="indexList.website_RD_staff_name" :duration="3000" class="num_sty">
                  </countTo>
                  <span class="jia_sty"><b>+</b></span>
                </div>
                <div class="zi_sty">研发人员</div>
              </td>
            </tr>
            <tr>
              <td class="shu wow fadeIn animated">
                <div>
                  <countTo :startVal="0" :endVal="numList.website_user_num" :duration="3000" class="num_sty"></countTo>
                  <span class="jia_sty"><b>+</b></span>
                </div>
                <div class="zi_sty">订单农业用户数</div>
              </td>
            </tr>
            <tr>
              <td class="shu1 wow fadeIn animated">
                <div>
                  <countTo :startVal="0" :endVal="numList.website_programme" :duration="3000" class="num_sty"></countTo>
                  <span class="jia_sty"><b>+</b></span>
                </div>
                <div class="zi_sty">智慧农业方案</div>
              </td>
            </tr>
            <tr>
              <td class="shu1 wow fadeIn animated">
                <div>
                  <countTo :startVal="0" :endVal="numList.website_service_coverage_cities" :duration="3000"
                    class="num_sty"></countTo>
                  <span class="jia_sty"><b>+</b></span>
                </div>
                <div class="zi_sty">智慧农业覆盖村社</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div :class="test1Show ? 'index-pro' : 'index-pro1'" ref="test1">
    <div class="swiper-wrapper">
      <div class="indexSolution1" v-for="(item, index) in prescription" :key="index">
        <div class="backimg">
          <img :src="'https://zhny.wwll.cc/' + item.avatar" :alt="item.remark" :title="item.remark">
        </div>
        <div class="titleNr">
          <div class="t">
            {{ item.title }}
            <br />
          </div>
          <div class="en">{{ item.content }}</div>
          <div class="icon">
            <div class="img" v-if="index == 0">
              <div class="lsyd"></div>
            </div>
            <div class="img" v-if="index == 1">
              <img src="../assets/trgl.png" alt="" />
            </div>
            <div class="img" v-if="index == 2">
              <img src="../assets/zhny.png" alt="" />
            </div>
          </div>
        </div>

      </div>
    </div>
    <fanganswiper class="swiper-fangan"></fanganswiper>
  </div>
  <div class="index-partners">
    <div class="index-partnersBox">
      <div :class="test2Show ? 'indexTitleBox2' : 'indexTitleBox2_fu'" ref="test2">
        <div class="indexTitle3">
          <div class="en">Yunbai Smart Agriculture New Era Partner</div>
          <div class="cn">云柏智慧农业新时代合伙人</div>

          <div class="pay"></div>
        </div>
        <div class="button_two">
          <div @click="topay(1)">
            <button type="button" class="button">
              <div class="button-top">体验云柏AI农业</div>
              <div class="button-bottom"></div>
              <div class="button-base"></div>
            </button>
          </div>
          <div @click="topay(2)">
            <button type="button" class="button">
              <div class="button-top">体验云柏订单农业</div>
              <div class="button-bottom"></div>
              <div class="button-base"></div>
            </button>
          </div>
        </div>
        <div class="wz">
          <div class="wzNr">
            {{ indexList.website_associate }}
          </div>
        </div>
      </div>
      <div :class="test3Show ? 'indexPartnersList' : 'indexPartnersList_fu'" ref="test3">
        <ul class="indexPartnersListBox">
          <li class="item" v-for="(item, index) in coverage_cities" :key="index" v-if="coverage_cities != ''">
            <div class="imgBox">
              <img :src="'https://zhny.wwll.cc/' + item.avatar" :alt="item.remark" :title="item.remark" />
            </div>
            <div class="title">
              <div class="titleNr1">
                <div class="t">
                  <span class="cont_sty"><i class="i1"></i>{{ item.content }}</span>
                </div>
              </div>
            </div>
          </li>
          <li class="item" v-else>
            <el-empty description="暂时没有数据" />
          </li>
        </ul>
      </div>
<!--      <div class="MoreBtn" ref="test4">-->
<!--        <span class="chakanname">查看更多 </span>-->
<!--        <span class="ArrowRightBold"><el-icon>-->
<!--            <ArrowRightBold />-->
<!--          </el-icon></span>-->
<!--      </div>-->
    </div>
  </div>
  <div class="index-news">
    <div class="index-newsBox">
      <div :class="acc1Show? 'indexTitle4' : 'indexTitle4_fu'" ref="acc1">
        <div class="indexTitle4-left">
          <div class="en">
            New Era Military Creation Yunbai Smart Agriculture Promotion
            Dynamics
          </div>
          <div class="cn">
            <span>新时代军创</span> <span>云柏智慧农业推广动态</span>
          </div>
        </div>
        <div class="indexTitle4-right">
          <div class="MoreBtn" @click="topage(2)">
            <span class="chakanname">查看更多 </span>
            <span class="ArrowRightBold"><el-icon>
                <ArrowRightBold />
              </el-icon></span>
          </div>
        </div>
      </div>
      <div :class="acc2Show? 'indexNewsList' : 'indexNewsList_fu'" ref="acc2">
        <div class="indexNewstu">
          <div class="imgb">
            <img :src="getAvatarUrl(associate[6])" :alt="getremark(associate[6])" :title="getremark(associate[6])" />
          </div>
        </div>
        <div class="indexNewsListul">
          <div class="li1">
            <div style="color: #4b7bfc;">{{ gettitle(associate[0]) }}</div>
            <img :src="getAvatarUrl(associate[0])" :alt="getremark(associate[0])" :title="getremark(associate[0])" />
          </div>
          <div class="li2">
            <div style="color: #4b7bfc;">{{ gettitle(associate[1]) }}</div>
            <img :src="getAvatarUrl(associate[1])" :alt="getremark(associate[1])" :title="getremark(associate[1])" />
          </div>
          <div class="li3">
            <div style="color: #4b7bfc;">{{ gettitle(associate[2]) }}</div>
            <img :src="getAvatarUrl(associate[2])" :alt="getremark(associate[2])" :title="getremark(associate[2])" />
          </div>
          <div class="li4">
            <div style="color: #4b7bfc;">{{ gettitle(associate[3]) }}</div>
            <img :src="getAvatarUrl(associate[3])" :alt="getremark(associate[3])" :title="getremark(associate[3])" />
          </div>
        </div>
        <div class="indexNewsListulphone">
          <div class="li1">
            <div style="color: #4b7bfc;">{{ gettitle(associate[2]) }}</div>
            <img :src="getAvatarUrl(associate[2])" :alt="getremark(associate[2])" :title="getremark(associate[2])" />
          </div>
          <div class="li2">
            <div style="color: #4b7bfc;">{{ gettitle(associate[3]) }}</div>
            <img :src="getAvatarUrl(associate[3])" :alt="getremark(associate[3])" :title="getremark(associate[3])" />
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="index-client">
    <div :class="acc3Show ? 'title' : 'title1'" ref="acc3">{{ indexList.website_work_together }}</div>
    <div :class="acc4Show ? 'phone' : 'phone1'" ref="acc4" @click="callPhone(1)">13072378000</div>
    <div :class="acc5Show ? 'phoneOne' : 'phoneOne1'" ref="acc5" @click="callPhone(2)">13114011822</div>
  </div>
  <div id="dwmap" class="dwmap">
    <div id="my-panel"></div>
  </div>
  <div class="addweixinpop" v-if="addshow" >
    <div class="addweixinpop_box" >
      <div class="cancel" @click="cancel" >
        <el-icon :size="20" ><Close style="width: 100%;height: 100%"  /></el-icon>
      </div>
      <div class="success" >
        <el-icon  :size="40"><CircleCheck style="width: 100%;height: 100%" /></el-icon>
      </div>
      <div class="title" >复制成功</div>
      <div class="tips" >微信号:13368029994</div>
      <div class="tips" >添加微信好友，详细了解产品</div>
      <div class="btn" @click="openweixin" >
      打开微信
      </div>
    </div>
  </div>
  <div v-if="isShow" class="name_sty" style="position: fixed" :style="{'top': showWidth + 'px','left': showHeight + 'px'}">
    云柏(重庆)智慧农业科技有限公司
  </div>
</template>

<script setup>
import {Close,CircleCheck} from '@element-plus/icons-vue'
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard()
import { ArrowRightBold } from "@element-plus/icons-vue";
import { onMounted, onUnmounted, ref, defineEmits, computed } from "vue";
import { API } from "../../api/index";
import { useRouter } from 'vue-router';
import fanganswiper from "./indexswiper.vue";
import { useMainStore } from '@/store/index.js'
import {CountTo} from "vue3-count-to";
import AMapLoader from "@amap/amap-jsapi-loader";


const mainStore = useMainStore()
let map = ref(null);
const router = useRouter();
const emit = defineEmits(['tabsnav'])

//鼠标放上查看更多事件
let isShow = ref(false)
let showWidth = ref(0)
let showHeight = ref(0)
function lookMore_mouseenter(event){
  const {x,y} = event
  isShow.value = true
  showWidth.value = y + 20
  showHeight.value = x + 10
}
//鼠标移出查看更多事件
function lookMore_mouseout(){
  isShow.value = false
  console.log('鼠标移出')
}

const acc = ref(null);
const accShow = ref(false)
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      accShow.value = true
    }
  });
});
const test1 = ref(null);
const test1Show = ref(false)
const observer1 = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      test1Show.value = true
    }
  });
});
const test2 = ref(null);
const test2Show = ref(false)
const observer2 = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      test2Show.value = true
    }
  });
});
const test3 = ref(null);
const test3Show = ref(false)
const observer3 = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      test3Show.value = true
    }
  });
});

const acc1 = ref(null);
const acc1Show = ref(false)
const observer4 = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      acc1Show.value = true
    }
  });
});

const acc2 = ref(null);
const acc2Show = ref(false)
const observer5 = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      acc2Show.value = true
    }
  });
});

const acc3 = ref(null);
const acc3Show = ref(false)
const observer6 = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      acc3Show.value = true
    }
  });
});
const acc4 = ref(null);
const acc4Show = ref(false)
const observer7 = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      acc4Show.value = true
    }
  });
});
const acc5 = ref(null);
const acc5Show = ref(false)
const observer8 = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      acc5Show.value = true
    }
  });
});

onMounted(() => {
  observer.observe(acc.value);
  observer1.observe(test1.value);
  observer2.observe(test2.value);
  observer3.observe(test3.value);
  observer4.observe(acc1.value);
  observer5.observe(acc2.value);
  observer6.observe(acc3.value);
  observer7.observe(acc4.value);
  observer8.observe(acc5.value);
  getquanjingdata();
  getadress()
});
// 定义 URL 数组
const urls = [
  "http://113.207.112.150:8992/3d_map/pano/gwqj.html?jsc=1&jid=27410",
  "http://113.207.112.150:8992/3d_map/pano/gwqj.html?jsc=1&jid=27411",
  "http://113.207.112.150:8992/3d_map/pano/gwqj.html?jsc=1&jid=41404",
  "http://113.207.112.150:8992/3d_map/pano/gwqj.html?jsc=1&jid=41369",
  "http://113.207.112.150:8992/3d_map/pano/gwqj.html?jsc=1&jid=42068",
  "http://113.207.112.150:8992/3d_map/pano/gwqj.html?jsc=1&jid=41677",
  "http://113.207.112.150:8992/3d_map/pano/gwqj.html?jsc=1&jid=27419",
  "http://zxbigdata.allxiangcun.cn//pano/index.html?jsc=1&jid=3",
  "http://zxbigdata.allxiangcun.cn//pano/index.html?jsc=1&jid=2",
  "http://zxbigdata.allxiangcun.cn//pano/index.html?jsc=1&jid=1",
];

const marker = ref(null);
// 定义一个响应式引用用于存储随机 URL
const randomUrl = ref("");
const latitude = ref('')
const longitude = ref('')

const city = ref('')
function getadress() {
  window._AMapSecurityConfig = {
    securityJsCode: "f902f26ff2a29756cf51384dda1ec0eb",
  };
  let startLngLat = ref([])
  AMapLoader.load({
    key: "24f840a20d3c6782254aa2fa03a2a8e0", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: [], // 需要使用的插件列表
  }).then((AMap) => {
    // 初始化地图
    map.value = new AMap.Map('dwmap', {
      viewMode: "3D", // 是否为3D地图模式
      zoom: 18, // 初始化地图级别
      center:  [106.53566697299175,29.565773622268193], // 初始化地图中心点位置
    });




    let screenWidth = document.documentElement.clientWidth;
    if (screenWidth >= 750) {
      const BMap = window.BMapGL;
      const BMapGeolocation = new BMap.Geolocation();
      BMapGeolocation.getCurrentPosition(r => {
        if (r.latitude && r.longitude) {
          startLngLat.value = [r.longitude, r.latitude]
          console.log("baidu", r);

          AMap.plugin(["AMap.Driving"], function () {
            const driving = new AMap.Driving({
              map: map.value,
              panel: "my-panel", //参数值为你页面定义容器的 id 值<div id="my-panel"></div>
            });
            const endLngLat = [106.53566697299175,29.565773622268193] //终点坐标
            //获取起终点规划线路
            driving.search(startLngLat.value, endLngLat, function (status, result) {
              if (status === "complete") {
                //status：complete 表示查询成功，no_data 为查询无结果，error 代表查询错误
                //查询成功时，result 即为对应的驾车导航信息
                console.log(result);
              } else {

              }
            });
          });

        } else {
          console.log(22);
        }
      });
    } else if (screenWidth <= 750) {
      //高德地图
      AMap.plugin("AMap.Geolocation", () => {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });


        geolocation.getCurrentPosition(function (status, result) {
          console.log("status, result", status, result);
          if (status === "complete") {
            console.log("成功:", JSON.stringify(result.position));
            startLngLat.value = result.position
            // //引入和创建驾车规划插件
            AMap.plugin(["AMap.Driving"], function () {
              const driving = new AMap.Driving({
                map: map.value,
                panel: "my-panel", //参数值为你页面定义容器的 id 值<div id="my-panel"></div>
              });



              const endLngLat = [106.53566697299175,29.565773622268193] //终点坐标
              //获取起终点规划线路
              driving.search(startLngLat.value, endLngLat, function (status, result) {
                if (status === "complete") {
                  //status：complete 表示查询成功，no_data 为查询无结果，error 代表查询错误
                  //查询成功时，result 即为对应的驾车导航信息
                  console.log(result);
                } else {

                }
              });
            });
          } else {
            // console.log("获取驾车数据失败：" + result);
            API.get_map().then(res => {
              let rectangle = res.data.data.rectangle
              console.log(rectangle);
              const afterSemicolon = res.data.data.rectangle.split(';')[1];
              const coordinates = afterSemicolon.split(',').map(coord => parseFloat(coord));
              console.log(coordinates);
              AMap.plugin(["AMap.Driving"], function () {
                const driving = new AMap.Driving({
                  map: map.value,
                  panel: "my-panel", //参数值为你页面定义容器的 id 值<div id="my-panel"></div>
                });



                const endLngLat = [106.53566697299175,29.565773622268193] //终点坐标
                //获取起终点规划线路
                driving.search(coordinates, endLngLat, function (status, result) {
                  if (status === "complete") {
                    //status：complete 表示查询成功，no_data 为查询无结果，error 代表查询错误
                    //查询成功时，result 即为对应的驾车导航信息
                    console.log(result);
                  } else {

                  }
                });
              });
            })
          }
        });
      });

    }




  }).catch((e) => {
    console.log(e);
  });








}



let indexList = ref([]);
let numList = ref({
  website_RD_staff_name: 0,
  website_programme: 0,
  website_service_coverage_cities: 0,
  website_user_num: 0,
})
// 解决方案
let prescription = ref([])
//新时代合伙人配置
let coverage_cities = ref([])
//推广动态
let associate = ref([])
function getquanjingdata() {
  API.getquanjing()
    .then((res) => {

      mainStore.setData(res.data.data);


      randomUrl.value = res.data.data[360].link_url;
      indexList.value = res.data.data;
      console.log('数据',indexList.value)
      numList.value.website_programme = indexList.value.website_programme
      numList.value.website_RD_staff_name = indexList.value.website_RD_staff_name
      numList.value.website_user_num = indexList.value.website_user_num
      numList.value.website_service_coverage_cities = indexList.value.website_service_coverage_cities
      prescription.value = res.data.data.prescription
      coverage_cities.value = res.data.data.coverage_cities
      associate.value = res.data.data.associate
      localStorage.setItem('website_seokeyword', res.data.data.website_seokeyword);
    })
}
function getAvatarUrl(item) {
  if (item && item.avatar) {
    return 'https://zhny.wwll.cc/' + item.avatar;
  }
  return ''; // 或者返回默认图片URL
}
function getremark(item) {
  if (item && item.remark) {
    return item.remark;
  }
  return ''; // 或者返回默认图片URL
}
function gettitle(item) {
  if (item && item.title) {
    return item.title;
  }
  return ''; // 或者返回默认图片URL
}
function switchMenu() {

  document.getElementById("show").scrollIntoView({ behavior: "smooth" });
}

function topay(index) {
  if (index == 1) {
    router.push({
      path: '/pay',
      // query: {
      //   title: '666'
      // }
    })
  } else if (index == 2) {
    router.push({
      path: '/pay2',
      // query: {
      //   title: '666'
      // }
    })
  }



}
function cancel(){
  addshow.value = false
}
const copy = async (Msg) => {
  try {
    //复制
    await toClipboard(Msg)
    console.log(Msg)
    //下面可以设置复制成功的提示框等操作
    //...
  } catch (e) {
    //复制失败
    console.error(e)
  }
}
function openweixin() {
  let Msg = '13114011822'

  copy(Msg)



  // window.location.href = 'weixin://';
}
let addshow = ref(false)
function openweixinshow() {
  addshow.value = !addshow.value
}
function callPhone(index){
  if (index == 1) {
    window.location.href = "tel://"+13072378000;
  }
  if (index == 2) {
    window.location.href = "tel://"+13114011822;
  }
}
function topage(index) {
  if (index == 1){
    router.push('/aboutny')
  }
  if (index == 2){
    router.push('/MilitaryCreation')
  }
}
onUnmounted(() => {
  map.value?.destroy();
});
</script>
<style lang="scss" scoped>
.ind1 {
  position: relative;
}
.dwmap{
  width: 100%;
  height: 600px;
}
.map {
  width: 100%;
  height: 95vh;
  position: relative;
  // z-index: -1;
}

.more {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 1111;

  img {
    width: 100%;
    height: 100%;
  }

  bottom: 45px;
  right: 45px;
}

.index-about {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.index-about .index-aboutBox {
  width: 83.33333333333333%;
  margin: 0 auto;
  padding: 0 3%;
}

.indexTitle2 {
  padding-bottom: 50px;
}

.indexTitle2 .cn {
  font-size: 3.75rem;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(57, 147, 237, 1);
  margin-bottom: 30px;
}

.indexTitle2 .en {
  font-size: 2.625rem;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(57, 147, 237, 1);
  padding-bottom: 35px;
}

.index-about .wz {
  font-size: 1.6rem;
  line-height: 40px;
  color: #000000;
  text-align: center;
}

.indexabout-back {
  width: 100%;
  height: 866px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  .img {
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
.name_sty{
  border: 1px solid #000;
  padding: 2px 0.5%;
}
.MoreBtn {
  width: 30%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 10px;
  cursor: pointer;
  margin:  0 auto;
  z-index: 2;
}

.MoreBtn .chakanname {
  width: 100%;
  display: inline-block;
  transition: all 0.3s;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0px;

  color: rgba(56, 56, 56, 1);
}

.ArrowRightBold {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  opacity: 1;
  background: rgba(57, 147, 237, 1);
  border-radius: 100%;
  color: #fff;
}

.indexAboutBox,.indexAboutBox1{
  padding-top: 100px;
}
.indexAboutBox {
  position: relative;
  animation: acc_ani 2s forwards;
}

@keyframes acc_ani {
  0% {
    visibility: hidden;
    opacity: 0;
    top: 100px;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}
.pageshuju td {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0.875rem;
  color: #000000;
  line-height: 30px;
  text-align: left;
  min-width: 200px;
  padding-left: 50px;
}

.pageshuju td h2 {
  font-size: 1.5rem;
  color: #e60012;
  font-weight: 600;
  text-align: left;
}

.pageshuju td h2 strong {
  font-size: 3.75rem;
  line-height: normal;
  font-weight: 600;
  font-family: "Swis721 BlkCn BT", Arial, Helvetica, sans-serif;
  color: #fff;
  background-image: -webkit-linear-gradient(18deg, #4f98fe, #4b7bfc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pageshuju td h2 span {
  font-size: 1.125rem;
  font-weight: 500;
  position: relative;
  top: 0;
  vertical-align: top;
  margin-left: 10px;
  color: #4b7bfc;
}

.pageshuju td h2 b {
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "Swis721 BlkCn BT", Arial, Helvetica, sans-serif;
}

.indexAboutBoxcontent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  div {
    position: relative;
  }

  .shu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .shu1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .num_sty {
    font-size: 4rem;
    font-weight: 600;
    color: #4d90fc;
  }

  .jia_sty {
    font-size: 1.5rem;
    font-weight: 600;
    color: #4d90fc;
    position: absolute;
    top: -40px;
    right: -20px;
  }

  .zi_sty {
    font-size: 1.5rem;
    margin-top: 10px;
  }
}

.index-pro,
.index-pro1 {
  height: 56.25vw;
  width: 100%;
}

.index-pro {
  position: relative;
  animation: slide-in 2s forwards;
}

@keyframes slide-in {
  0% {
    visibility: hidden;
    opacity: 0;
    top: 100px;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1.0625rem;
  justify-content: space-between;
}

.indexSolution1 {
  width: 33%;
  height: 100%;
  position: relative;

}

.backimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: aqua;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.titleNr {
  padding-top: 236px;
}

.titleNr .t {
  text-align: center;
  font-size: 4.5rem;
  font-weight: 700;
  color: #fff;
  line-height: normal;
  width: 81%;
  margin: 0 auto;
}

.titleNr .en {
  text-align: center;
  font-size: 0.75rem;
  color: #fff;
  line-height: 20px;
  text-transform: uppercase;
  padding-top: 14px;
}

.titleNr .icon {
  display: flex;
  justify-content: center;
  margin-top: 2.916666666666667vw;
  transition: all 0.3s;
  position: relative;
}

.titleNr .icon .img {
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  .lsyd {
    left: 8.36px;
    top: 9.32px;
    width: 19.4px;
    height: 19.99px;
    opacity: 1;
    background: rgba(64, 186, 50, 1);
    border-radius: 100%;
  }

  img {
    width: 70%;
    height: 70%;
  }
}

.titleNr .icon:before {
  content: "";
  background: #fff;
  position: absolute;
  top: 55%;
  left: 50%;
  border-radius: 50%;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  width: 66px;
  height: 66px;
  margin-top: -33px;
  margin-left: -33px;
  filter: alpha(opacity=30);
  -moz-opacity: 0.3;
  -khtml-opacity: 0.3;
  opacity: 0.3;
  z-index: 1;
  animation: mymoveli2 1s ease-out infinite;
}

.titleNr .icon:after {
  content: "";
  background: #fff;
  position: absolute;
  top: 55%;
  left: 50%;
  border-radius: 50%;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  width: 82px;
  height: 82px;
  margin-top: -42px;
  margin-left: -42px;
  filter: alpha(opacity=10);
  -moz-opacity: 0.1;
  -khtml-opacity: 0.1;
  opacity: 0.1;
  z-index: 0;
  animation: mymoveli1 1s ease-out infinite;
}

@keyframes mymoveli2 {
  from {
    transform: scale3d(0.9, 0.9, 1);
  }

  to {
    transform: scale3d(1.2, 1.2, 1);
  }
}

@keyframes mymoveli1 {
  from {
    transform: scale3d(0.9, 0.9, 1);
  }

  to {
    transform: scale3d(1.3, 1.3, 1);
  }
}

.bottomimg1 {
  width: 100%;
  height: 355px;
  position: absolute;
  bottom: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.bottomimg2 {
  width: 100%;
  height: 307px;
  position: absolute;
  bottom: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.bottomimg3 {
  width: 100%;
  height: 276px;
  position: absolute;
  bottom: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.index-partners {
  padding-top: 100px;
  padding-bottom: 80px;
  overflow: hidden;
}

.index-partners .index-partnersBox {
  width: 90%;
  margin: 0 auto;
}

.index-partners .indexTitleBox .indexTitle2 {
  width: 38.75%;
  text-align: right;
  float: left;
  padding-top: 15px;
}

.indexTitle3 .en {
  font-size: 0.875rem;
  color: #4b7bfc;
  font-weight: 400;
  text-transform: uppercase;
}

.indexTitle3 .cn {
  font-size: 2.5rem;
  color: #4b7bfc;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
  position: relative;
}

.indexTitle3 .AIGC {
  font-size: 2.75rem;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 56px;
  color: rgba(57, 147, 237, 1);
  text-align: right;
}

.pay-text {

  background: rgba(57, 147, 237, 1);
  font-size: 1.3125rem;
  // font-weight: 700;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  // padding: 49px 40px;
  height: 132px;
  line-height: 132px;
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  position: relative;
  width: 280px;

  img {
    width: 45px;
    height: 45px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 4.21053rem;
  }
}

.button_two {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.button {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  border-width: 0;
  padding: 0 8px 12px;
  min-width: 10em;
  box-sizing: border-box;
  background: transparent;
  font: inherit;
  cursor: pointer;
}

.button-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  padding: 8px 16px;
  transform: translateY(0);
  text-align: center;
  color: #fff;
  text-shadow: 0 -1px rgba(0, 0, 0, .25);
  transition-property: transform;
  transition-duration: .2s;
  -webkit-user-select: none;
  user-select: none;
}

.button:active .button-top {
  transform: translateY(6px);
}

.button-top::after {
  content: '';
  position: absolute;
  z-index: -1;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  background-image: radial-gradient(#4b7bfc, #4b7bfc);
  text-align: center;
  color: #fff;
  box-shadow: inset 0 0 0px 1px rgba(255, 255, 255, .2), 0 1px 2px 1px rgba(255, 255, 255, .2);
  transition-property: border-radius, padding, width, transform;
  transition-duration: .2s;
}

.button:active .button-top::after {
  border-radius: 6px;
  padding: 0 2px;
}

.button-bottom {
  position: absolute;
  z-index: -1;
  bottom: 4px;
  left: 4px;
  border-radius: 8px / 16px 16px 8px 8px;
  padding-top: 6px;
  width: calc(100% - 8px);
  height: calc(100% - 10px);
  box-sizing: content-box;
  background-color: #4b7bfc;
  background-image: radial-gradient(4px 8px at 4px calc(100% - 8px), rgba(255, 255, 255, .25), transparent), radial-gradient(4px 8px at calc(100% - 4px) calc(100% - 8px), rgba(255, 255, 255, .25), transparent), radial-gradient(16px at -4px 0, white, transparent), radial-gradient(16px at calc(100% + 4px) 0, white, transparent);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5), inset 0 -1px 3px 3px rgba(0, 0, 0, .4);
  transition-property: border-radius, padding-top;
  transition-duration: .2s;
}

.button:active .button-bottom {
  border-radius: 10px 10px 8px 8px / 8px;
  padding-top: 0;
}

.button-base {
  position: absolute;
  z-index: -2;
  top: 4px;
  left: 0;
  border-radius: 12px;
  width: 100%;
  height: calc(100% - 4px);
  background-color: rgba(0, 0, 0, .15);
  box-shadow: 0 1px 1px 0 rgba(255, 255, 255, .75), inset 0 2px 2px rgba(0, 0, 0, .25);
}

.pay {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.indexTitleBox2,
.indexTitleBox2_fu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.indexTitleBox2 {
  position: relative;
  animation: indexTitleBox2_ani 4s forwards;
}

@keyframes indexTitleBox2_ani {
  0% {
    visibility: hidden;
    opacity: 0;
    top: 100px;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

.index-partners .indexTitleBox2 .wz {
  // width: 55.625%;
  text-align: left;
  float: right;
  font-size: 0.9375rem;
  line-height: 30px;
  color: #000000;
}

.index-partners .indexTitleBox2 .wzNr {
  max-width: 525px;
}

.indexPartnersList,
.indexPartnersList_fu {
  margin: 0 auto;
  padding-top: 85px;
  max-width: 1260px;
}

.indexPartnersList {
  position: relative;
  animation: indexPartnersList_ani 4s forwards;
}

@keyframes indexPartnersList_ani {
  0% {
    visibility: hidden;
    opacity: 0;
    top: 100px;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

.indexPartnersList .imgBox img {
  width: 100%;
  height: 40vh;
  background-color: #cccccc;
}

.indexPartnersList .item {
  padding-bottom: 20px;
  display: block;
  margin: 0 10px;
  position: relative;
  background: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 5px;
  transition: all 0.3s;
  width: 25%;
  cursor: pointer;
}

.indexPartnersList .item:hover {
  transform: translateY(-10px);
}

.indexPartnersList .title {
  margin-left: 19px;
}

.indexPartnersList .title .t {
  font-size: 1.125rem;

  color: #4b7dfc;
}

.indexPartnersList .title .t span {
  display: inline-block;
  padding: 3px 10px;
  position: relative;
  z-index: 100;
  color: #fff;
  font-size: 1rem;
}

.indexPartnersList .title .t span:before {
  content: "";
  border: 1px solid #dbe5fe;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.indexPartnersList .title .t span:after {
  content: "";
  background-color: rgba(40, 125, 252, 1);
  position: absolute;
  left: 5px;
  top: 5px;
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: -1;
}

.indexPartnersList .title .t span .i1 {
  display: inline-block;
  position: absolute;
  left: -14px;
  top: 50%;
  margin-top: -3.5px;
  width: 7px;
  height: 7px;
  background: #4b7dfc;
  border-radius: 50%;
  overflow: hidden;
}

.indexPartnersListBox {
  display: flex;
  justify-content: space-between;
}

.indexPartnersList li:nth-child(2n) {
  position: relative;
  top: 50px;
}

.index-partners .MoreBtn {
  text-align: center;
  padding-top: 50px;
}

.index-news {
  padding-top: 100px;
  padding-bottom: 80px;
  background: url(https://www.visfarm.com/Design/King-Tin/images/index-newsbg.jpg) top center no-repeat;
  background-size: cover;
  overflow: hidden;
}

.indexTitle2 {
  padding-bottom: 50px;
  position: relative;
}

.index-news .index-newsBox {
  width: 83.33333333333333%;
  margin: 0 auto;

  .indexTitle4,indexTitle4_fu {
    padding-bottom: 50px;
    .indexTitle4-right{
      width: 18%;
    }
  }
  .indexTitle4 {
    position: relative;
    animation: indexTitle4-in 2s forwards;
  }

  @keyframes indexTitle4-in {
    0% {
      visibility: hidden;
      opacity: 0;
      top: 100px;
    }

    100% {
      top: 0px;
      opacity: 1;
    }
  }
  .indexTitle4 .cn {
    font-size: 2.5rem;
    color: #4b7bfc;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 15px;
    position: relative;
  }

  .indexTitle4 .en {
    font-size: 0.875rem;
    color: #4b7bfc;
    font-weight: 400;
    text-transform: uppercase;
  }

  .indexTitle4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.indexNewsList,.indexNewsList_fu {
  margin: 0 auto;
  display: flex;
  gap: 49px;

  .indexNewstu {
    width: 405px;
    // height: 510px;
    border-radius: 5px;
    overflow: hidden;

    .imgb {
      position: relative;
      width: 100%;
      // height: 100%;
      // overflow: hidden;
    }

    .imgb img {
      display: block;
      width: 100%;
      height: auto;
      // height: 100%;
      // max-width: 100%;
      // object-fit: cover;
      // transition: all 0.5s;
    }
  }
}
.indexNewsList {
  position: relative;
  animation: indexNewsList-in 2s forwards;
}

@keyframes indexNewsList-in {
  0% {
    visibility: hidden;
    opacity: 0;
    left: 100%;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}


.indexNewsListul {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;

  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    justify-content: space-between;

    img {
      border-radius: 10px;
      width: 100%;
      object-fit: cover;
      height: 72px;
      flex-grow: 1;
    }
  }
}

.indexNewsListulphone {
  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    justify-content: space-between;

    img {
      border-radius: 10px;
      width: 100%;
      object-fit: cover;
      flex-grow: 1;
    }
  }
}

.index-client {
  height: 509px;
  background: url(../assets/hezuo.png) top center no-repeat;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3.75rem;
  font-weight: 700;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  .title,.title1{
    font-size: 3.5rem;
    font-weight: 700;
  }
  .title {
    position: relative;
    animation: title-in 2s forwards;
  }
  .phone,.phone1{
    font-size: 3.75rem;
    font-weight: 700;
  }
  .phone {
    position: relative;
    animation: phone-in 2s forwards;
  }
  .phoneOne,.phoneOne1{
    font-size: 3.75rem;
    font-weight: 700;
  }
  .phoneOne {
    position: relative;
    animation: phoneOne-in 2s forwards;
  }
}
@keyframes title-in {
  0% {
    visibility: hidden;
    opacity: 0;
    top: 100px;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes phone-in {
  0% {
    visibility: hidden;
    opacity: 0;
    top: 100px;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes phoneOne-in {
  0% {
    visibility: hidden;
    opacity: 0;
    top: 100px;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}
.more {
  animation: myAnimation 1s infinite;
  cursor: pointer;
}

@keyframes myAnimation {
  0% {
    transform: translateY(-20%);
  }

  100% {
    transform: translateY(0%);
  }
}

.swiper-fangan {
  display: none;
}

.indexNewsListulphone {
  display: none;
}

.addweixin {
  display: none;
}

@media screen and (max-width: 1004px) {
  .dwmap{
    width: 100%;
    height: 30vh;
  }

  .index-news .index-newsBox .indexTitle4 .indexTitle4-right{
    width: 100%;
  }
  .map {
    width: 100%;
    height: 45vh;
    position: relative;
    // z-index: -1;
  }

  .more {
    display: none;
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 1111;

    img {
      width: 100%;
      height: 100%;
    }

    bottom: 45px;
    right: 45px;
  }

  .indexTitle2 .cn {
    font-size: 2rem;
    margin-bottom: 0;
  }

  .indexTitle2 .en {
    font-size: 1.5rem;
  }
  .MoreBtn {
    width: 20%;
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-top: 10px;
    cursor: pointer;
    margin:  0 auto;
  }
  .MoreBtn .chakanname {
    font-size: 1.3rem;
  }

  .MoreBtn .ArrowRightBold {
    height: 1.3rem;
    width: 1.3rem;
  }

  .titleNr .t {
    text-align: center;
    font-size: 5.5rem;
    font-weight: 700;
    color: #fff;
    line-height: normal;
    width: 81%;
    margin: 0 auto;
  }

  .el-icon svg {
    width: 10px;
    height: 10px;
  }

  .titleNr .en {
    text-align: center;
    font-size: 1.75rem;
    color: #fff;
    line-height: 20px;
    text-transform: uppercase;
    padding-top: 14px;
  }

  .indexAboutBoxcontent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    div {
      position: relative;
    }

    .shu {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .shu1 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 2rem 0;
    }

    .num_sty {
      font-size: 4rem;
      font-weight: 600;
      color: #4d90fc;
    }

    .jia_sty {
      font-size: 1.5rem;
      font-weight: 600;
      color: #4d90fc;
      position: absolute;
      top: -40px;
      right: -20px;
    }

    .zi_sty {
      font-size: 1.5rem;
      margin-top: 10px;
    }
  }

  .pageshuju td {
    min-width: 0;
  }

  .swiper-wrapper {
    display: none;
  }

  .swiper-fangan {
    display: block;
  }

  .index-pro {
    height: 23.9375rem;
    width: 100%;
  }

  .indexTitleBox2 {
    display: block;
  }

  .indexTitle3 .en {
    font-size: 1.3rem;
    text-align: center;
  }

  .indexTitle3 .cn {
    font-size: 2.6rem;
    text-align: center;
  }

  .button_two {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .button {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    border-width: 0;
    //padding: 0 8px 12px;
    min-width: 5em;
    box-sizing: border-box;
    background: transparent;
    font: inherit;
    cursor: pointer;
  }

  .button-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    //padding: 8px 16px;
    transform: translateY(0);
    text-align: center;
    color: #fff;
    text-shadow: 0 -1px rgba(0, 0, 0, .25);
    transition-property: transform;
    transition-duration: .2s;
    -webkit-user-select: none;
    user-select: none;
  }

  .button:active .button-top {
    transform: translateY(6px);
  }

  .button-top::after {
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    background-image: radial-gradient(#4b7bfc, #4b7bfc);
    text-align: center;
    color: #fff;
    box-shadow: inset 0 0 0px 1px rgba(255, 255, 255, .2), 0 1px 2px 1px rgba(255, 255, 255, .2);
    transition-property: border-radius, padding, width, transform;
    transition-duration: .2s;
  }

  .button:active .button-top::after {
    border-radius: 6px;
    padding: 0 2px;
  }

  .button-bottom {
    position: absolute;
    z-index: -1;
    bottom: 4px;
    left: 4px;
    border-radius: 8px / 16px 16px 8px 8px;
    padding-top: 6px;
    width: calc(100% - 8px);
    height: calc(100% - 10px);
    box-sizing: content-box;
    background-color: #4b7bfc;
    background-image: radial-gradient(4px 8px at 4px calc(100% - 8px), rgba(255, 255, 255, .25), transparent), radial-gradient(4px 8px at calc(100% - 4px) calc(100% - 8px), rgba(255, 255, 255, .25), transparent), radial-gradient(16px at -4px 0, white, transparent), radial-gradient(16px at calc(100% + 4px) 0, white, transparent);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5), inset 0 -1px 3px 3px rgba(0, 0, 0, .4);
    transition-property: border-radius, padding-top;
    transition-duration: .2s;
  }

  .button:active .button-bottom {
    border-radius: 10px 10px 8px 8px / 8px;
    padding-top: 0;
  }

  .button-base {
    position: absolute;
    z-index: -2;
    top: 4px;
    left: 0;
    border-radius: 12px;
    width: 100%;
    height: calc(100% - 4px);
    background-color: rgba(0, 0, 0, .15);
    box-shadow: 0 1px 1px 0 rgba(255, 255, 255, .75), inset 0 2px 2px rgba(0, 0, 0, .25);
  }

  .indexPartnersList .imgBox img {
    width: 90%;
    height: 20vh;
    background-color: #cccccc;
  }

  .indexPartnersList .title .t {
    font-size: 1.125rem;
    color: #4b7dfc;
  }

  .indexPartnersList .title .t span {
    display: inline-block;
    padding: 3px 10px;
    position: relative;
    z-index: 100;
    color: #fff;
    font-size: 1.5rem;
  }

  .pay-text {
    padding-top: 10px;
    padding-bottom: 50px;
    width: 57%;
    margin: 0 auto;
    border-radius: 100px;
    margin-top: 30px;
  }

  .pay-text img {
    top: 90px;
  }

  .index-partners .indexTitleBox2 .wz {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .index-partners .indexTitleBox2 .wzNr {
    text-indent: 59px;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .indexPartnersListBox {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 16px;
  }

  .indexPartnersList .item {
    width: 100%;
  }

  //.indexPartnersList .title .t span {
  //  font-size: .8125rem;
  //}

  .index-news .index-newsBox .indexTitle4 {
    display: block;
  }

  .index-news .index-newsBox .indexTitle4 .en {
    font-size: 1.2rem;
    text-align: center;
  }

  .index-news .index-newsBox .indexTitle4 .cn {
    font-size: 2rem;
    text-align: center;
  }

  .indexNewsList .indexNewstu {
    width: 368px;
  }

  // .indexNewsListul{
  //   flex: 1;
  //   display: grid;
  //   grid-template-columns: repeat(1, 1fr);
  //   gap: 25px;
  // }
  .indexNewsListul {
    display: none;
  }

  .indexNewsList {
    gap: .8125rem;
    justify-content: center;
  }

  .indexNewsListulphone {
    display: block;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;

    .li1 {
      background-color: #fff;
      width: 100%;
      font-size: 1.2rem;
    }

    .li2 {
      background-color: #fff;
      width: 100%;
      font-size: 1.2rem;
    }
  }

  .index-client {
    font-size: 2.5rem;
    line-height: 30px;
    .title{
      font-size: 2.5rem;
    }
    .phone{
      font-size: 2.5rem;
    }
    .phoneOne{
      font-size: 2.5rem;
    }
    div {
      margin: 1rem 0;
    }
  }

  .index-about {
    padding-bottom: 0px;
    margin-top: 60px;
  }

  .index-about {
    padding-top: 0px;
  }

  .addweixin {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 15px;
    box-sizing: border-box;

    .left {
      flex: 1;
    }

    .right {
      background: #44a92f;
      padding: 15px;
      display: flex;
      align-items: center;
      gap: 10px;

      .wechatpng {
        width: 40px;
        height: auto;
      }

      .wechat {
        font-size: 30px;
        color: #fff;
      }
    }
  }


}
.addweixinpop{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .4);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .addweixinpop_box{
    width: 82%;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 15px;
    box-sizing: border-box;
  }
  .cancel{
    text-align: right;
  }
  .success{
    text-align: center;
    color: #44a92f;
  }
  .title{
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    margin-top: 15px;
  }
  .tips{
    color: #999;
    font-size: 25px;
    margin-top: 10px;
    text-align: center;
  }
  .btn{
    width: 40%;
    height: 70px;
    background-color: #44a92f;
    font-size: 30px;
    line-height: 70px;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    margin:0 auto;
    margin-top: 10px;
  }
}
</style>
