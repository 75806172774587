// store/index.js
import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
  state: () => ({
    data: {}
  }),
  actions: {
    setData(data) {
      this.data = data;
    }
  }
});