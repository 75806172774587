<template>
    <div class="footer">
        <div class="footerbox">
            <div class="footer-top">
                <div class="lxBox">
                    <div class="footer-logo">
                        <img src="../assets/footer.png" alt="">

                    </div>
                    <div class="lxNr">
                        <ul>
                            <li class="item">
                                <div class="itemlogo setwha">
                                    <img src="https://zhny.wwll.cc/data/upload/assets/lj1.png" alt="">
                                </div>
                                <div class="toptips">{{ indexList.site_email }}</div>
                            </li>
                            <li class="item">
                                <div class="itemlogo setwhb">
                                    <img src="https://zhny.wwll.cc/data/upload/assets/lj2.png" alt="">
                                </div>
                                <div class="toptips">{{ indexList.site_phone }}</div>
                            </li>
                            <li class="item">
                                <div class="itemlogo setwhc">
                                    <img src="https://zhny.wwll.cc/data/upload/assets/lj3.png" alt="">
                                </div>
                                <div class="toptips">{{ indexList.website_http_url }}</div>
                            </li>
                            <li class="item">
                                <div class="itemlogo setwhd">
                                    <img src="https://zhny.wwll.cc/data/upload/assets/lj4.png" alt="">
                                </div>
                                <div class="toptips">{{ indexList.website_address }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="listBox">
                    <div class="footernav">
                        <div class="linli" v-for="(item, index) in lists" :key="index">
                            <div class="title" @click="tabnav(index)">
                                {{ item.title }}
                            </div>
                            <ul class="navigations" v-for="(it, id) in item.next" :key="id" @click="secondnavtabs(it)">
                                <li>{{ it.title }}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="pcqrcode">
                        <img src="../assets/yunbo.png" alt="">
                        <div class="tips">
                            扫码获得云柏智慧农业服务
                        </div>
                    </div>
                </div>
                <div class="qrcode">
                    <div class="yunbocode">
                        <img src="../assets/yunbo.png" alt="">
                        <div class="tips">扫码获得云柏智慧农业服务</div>
                    </div>

                </div>

            </div>

<!--            <div class="footer-bottom">-->
<!--                <div class="phone-nav">-->

<!--                    <ul>-->
<!--                        <li class="one" v-for="(list, index) in lists " :key="index">-->
<!--                            &lt;!&ndash; a标签不能带href，不然会刷新，刷新数据和没调用点击函数一样，此处踩坑了 &ndash;&gt;-->
<!--                            <a class="p_title">-->
<!--                                <span class="navleft" @click=phonetabsnav(index)>{{ list.title }}</span>-->
<!--                                <span class="navright" @click="curshow(list)">-->
<!--                                    <img src="../assets/加.png" alt="">-->
<!--                                </span>-->
<!--                            </a>-->
<!--                            <transition name="fade-slide">-->
<!--                                <ul class="secondlevel" v-if="list.flag">-->
<!--                                    <li v-for="sub in list.next" @click="secondphonetabsnav(sub)">-->
<!--                                        <a>{{ sub.title }}</a>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </transition>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
            <div class="footer-tips">
                渝ICP备2021007269号
            </div>
        </div>
    </div>
    <soilimpro v-if="issoilimpro" @offfalse="offfalse"></soilimpro>
</template>
<script setup>
import { ref, defineEmits, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useMainStore } from '@/store/index.js'
import soilimpro from '../components/soilimpro.vue'
import {API} from "../../api";
let mainStore = useMainStore();
let indexdata = ref([])

const router = useRouter()
const emit = defineEmits(["tabsnav"]);
let indexList = ref([])
// 移动端导航栏
let lists = ref([])
let issoilimpro = ref(false)
// 监听 mainStore 的变化
watch(mainStore, (newVal) => {
    indexdata.value = newVal.data
    let data = indexdata.value.categorisation_list
    // indexList.value = newVal.data
    // lists.value = data.map(item => ({
    //     ...item,
    //     flag: false,
    // }));

});
onMounted(() =>{
  API.getquanjing()
      .then((res) => {
        let data = res.data.data.categorisation_list
        indexList.value = res.data.data
        lists.value = data.map(item => ({
          ...item,
          flag: false,
        }));
      })
})
//pc端导航栏切换事件
function tabnav(index) {
    if (index == 0) {
        router.push("/");

    } else if (index == 4) {
        router.push("/contactus");
    }
    else if (index == 5) {
        router.push("/aboutny");
    }
    else if (index == 2) {
        router.push("/InternetThings");
    }
    else if (index == 3) {
        router.push("/MilitaryCreation");
    }
}
// PC端二级导航栏切换
function secondnavtabs(it) {
    console.log(it);
    if (it.title == '土壤改良') {
        router.push("/Soilimprovement");
    }
    if (it.title == '智慧农业') {
        router.push("/SmartAgriculture");
    }
    if (it.title == '社会化服务') {
        router.push("/socializedservices");
    }
    if (it.title == '智慧果园') {
        router.push("/SmartOrchard");
    }
    if (it.title == '智慧灌溉') {
        router.push("/Smartirrigation");
    }
    if (it.title == '智慧农业') {
        router.push("/SmartAgriculture");
    }
    if (it.title == '云柏订单农业') {
        router.push("/pay2");
    }
    if (it.title == '云柏AIGC农业') {
        router.push("/pay");
    }

}
// 土壤改良子组件传递过来的事件
function offfalse() {
    issoilimpro.value = false

}
// 移动端显示隐藏点击事件
function curshow(list) {
    //如果状态是点开的，那么再次点击就隐藏
    if (list.flag) {
        list.flag = false;
    }
    //若当前的是隐藏，则点击当前的显示，其余的隐藏
    else {
        for (let i = 0; i < lists.value.length; i++) {
            lists.value[i].flag = false;
        }
        list.flag = true;
    }

}

// 移动端一级导航栏点击事件
function phonetabsnav(index) {
    if (index == 0) {
        router.push("/");

    } else if (index == 4) {
        router.push("/contactus");
    }
    else if (index == 5) {
        router.push("/aboutny");
    }

}
// 移动端二级导航栏点击事件
function secondphonetabsnav(sub) {
    if (sub.title == '土壤改良') {
        issoilimpro.value = true
    }
}
</script>
<style lang="scss">
.footer {
    background-color: #eceff6;
    padding-top: 122px;
    padding-bottom: 60px;
    background-size: 100%;

    .footerbox {
        width: 80.20833333333333%;
        margin: 0 auto;
    }
}

.footer .footer-top {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;

}

.footer .footer-top .footer-logo {
    margin-bottom: 45px;
}

.footer .footer-top .footer-logo img {
    height: 50px;
    max-width: 100%;
}

.item {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 23px;
}



.toptips {
    color: rgba(74, 74, 74, 1);
}

.setwha {
    width: 42px;
    height: 33px;

    img {
        width: 100%;
        height: 100%;
    }
}

.setwhb {
    width: 38.5px;
    height: 35px;

    img {
        width: 100%;
        height: 100%;
    }
}

.setwhc {
    width: 37.5px;
    height: 37.5px;

    img {
        width: 100%;
        height: 100%;
    }
}

.setwhd {
    width: 35px;
    height: 40px;

    img {
        width: 100%;
        height: 100%;
    }
}

.footer .footer-top .footernav .title {
    font-size: 1rem;
    line-height: 30px;
    color: #000000;
    font-weight: 500;
    padding-bottom: 20px;
    cursor: pointer;
}

.footer .footer-top .footernav .navigations {
    margin-bottom: 30px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
}

.footer .footer-top .footernav .navigations li {
    font-size: .875rem;
    color: #646464;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    padding: 5px 0;

}

.listBox {
    .pcqrcode {
        text-align: center;

        img {
            width: 188px;
            height: 188px;
        }

        .tips {
            font-size: 1.25rem;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(92, 92, 92, 1);
        }
    }
}

.footernav {
    display: flex;
    justify-content: space-between;
    gap: 2.75rem;

}

.footer-tips {
    margin-top: 86px;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(74, 74, 74, 1);
}

.footer-bottom {
    margin-top: 86px;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: 0px;
    color: rgba(74, 74, 74, 1);
}

.qrcode {
    display: none;
}

.phone-nav {
    display: none;
}

@media screen and (max-width: 1004px) {
    .listBox {
        display: none;
    }

    .footernav {
        display: none;
    }

    .qrcode {

        display: block;

        .yunbocode {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;

            img {
                width: 30vw;
                height: auto;
            }
        }

        .tips {
            font-size: 1.1rem;
            font-weight: 400;
            letter-spacing: 0px;

            color: rgba(42, 130, 228, 1);

        }
    }
    .listBox {
      .pcqrcode {
        text-align: center;

        img {
          width: 200px;
          height: 200px;
        }

        .tips {
          font-size: 1.25rem;
          font-weight: 400;
          letter-spacing: 0px;
          color: rgba(92, 92, 92, 1);
        }
      }
    }
    .footer .footer-top {
        gap: 5.57895rem;
        justify-content: flex-start;
    }

    .footer .footerbox {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .footer {
        background-color: #fff;
    }
    .toptips {
      color: rgba(74, 74, 74, 1);
      font-size: 1.4rem;
    }
    .phone-nav {
        display: block;
        width: 100%;

        right: 0;
        top: 108px;
        background: #fff;
        z-index: 501;

        box-sizing: border-box;

        ul {
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 1);
            text-align: left;

            .one {
                border-bottom: 1px solid #f3f3f3;
                // padding: 10px 15px;
                box-sizing: border-box;


                font-size: 1.5rem;

                .p_title {
                    display: flex;
                    padding: 0 15px;
                    box-sizing: border-box;
                    justify-content: space-between;

                    .navright {
                        width: 26px;
                        height: 26px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .navleft {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    .secondlevel {
        background: #f9f9f9;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;

        .secondlevel-list {
            font-size: 1.5rem;
            display: block;
            color: #666;
            border-bottom: 1px solid #f3f3f3;
            padding: 10px 0;
            box-sizing: border-box;
        }
    }
}
</style>