import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aboutny',
    name: 'aboutny',
    component: () => import(/* webpackChunkName: "aboutny" */ '../views/aboutny.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import(/* webpackChunkName: "pay" */ '../views/pay.vue')
  },
  {
    path: '/pay2',
    name: 'pay2',
    component: () => import(/* webpackChunkName: "pay" */ '../views/pay2.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import(/* webpackChunkName: "pay" */ '../views/contactus.vue')
  },
  {
    path: '/Paysucces',
    name: 'Paysucces',
    component: () => import(/* webpackChunkName: "pay" */ '../views/Paysucces.vue')
  },
  {
    path: '/SecondaryDetails',
    name: 'SecondaryDetails',
    component: () => import(/* webpackChunkName: "SecondaryDetails" */ '../views/SecondaryDetails.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "SecondaryDetails" */ '../views/test.vue')
  },
  //智慧农业
  {
    path: '/SmartAgriculture',
    name: 'SmartAgriculture',
    component: () => import(/* webpackChunkName: "SecondaryDetails" */ '../views/components/SmartAgriculture.vue')
  },
  //土壤改良
  {
    path: '/Soilimprovement',
    name: 'Soilimprovement',
    component: () => import(/* webpackChunkName: "SecondaryDetails" */ '../views/components/Soilimprovement.vue')
  },

  //社会化服务
  {
    path: '/socializedservices',
    name: 'socializedservices',
    component: () => import(/* webpackChunkName: "SecondaryDetails" */ '../views/components/socializedservices.vue')
  },
  //云柏农业物联网应用案例
  {
    path: '/InternetThings',
    name: 'InternetThings',
    component: () => import(/* webpackChunkName: "SecondaryDetails" */ '../views/InternetThings.vue')
  },
  
  //云柏农业物联网应用案例下的智慧果园
  {
    path: '/SmartOrchard',
    name: 'SmartOrchard',
    component: () => import(/* webpackChunkName: "SecondaryDetails" */ '../views/components/SmartOrchard.vue')
  },
  //云柏农业物联网应用案例下的智慧灌溉
  {
    path: '/Smartirrigation',
    name: 'Smartirrigation',
    component: () => import(/* webpackChunkName: "SecondaryDetails" */ '../views/components/Smartirrigation.vue')
  },
  //新时代军创智慧农业动态
  {
    path: '/MilitaryCreation',
    name: 'MilitaryCreation',
    component: () => import(/* webpackChunkName: "SecondaryDetails" */ '../views/MilitaryCreation.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // scrollBehavior () { // 每次切换路由时，页面滚动的位置
  //   // return { x: 0, y: 0 }// vue2.0 写法
  //   return { left: 0, top: 0 }// vue3.0 写法
  // }
})

export default router
