<template>
  <div class="top" :class="{ 'is-white': iswhitebc, 'is-whitepc': isScrolledvlaue, }">
    <div class="topbox">
      <div class="top_left">

        <img class="img" src="https://yun-bo.net/data/guanwanglogo.png" alt="" v-if="isScrolledvlaue"/>
        <img class="img" src="https://yun-bo.net/data/guanwanglogo1.png" alt="" v-else/>
      </div>
      <div class="top_right">
        <!-- 循环渲染除了最后一个之外的所有pc端导航项 -->
        <div class="navlist">
          <div v-for="(item, index) in navItems" :key="index" class="navboxlist">
            <div class="navbox" :class="{ active: index === currentIndex, scrolled: isScrolledvlaue }"
                 @click="navtabs(index)">
              {{ item.title }}
            </div>
            <!-- 下拉菜单 -->
            <div v-if="item.hasDropdown" :class="{ 'drop-down': item.hasDropdown === true }">
              <div class="drop-down-list" v-for="(it, id) in item.next" :key="id" @click="secondnavtabs(it)">{{
                  it.title
                }}
              </div>
            </div>
          </div>
          <div class="sousuolist">
            <div class="navbox" :class="{ scrolled: isScrolledvlaue }" @click="tabssearchct()">
              <span>搜索</span>
              <Search style="width: 1em; height: 1em; margin-right: 8px" v-if="!isfrom"/>
              <CloseBold style="width: 1em; height: 1em; margin-right: 8px" v-if="isfrom"/>
            </div>
          </div>
        </div>
        <div class="otherbox">
          <div class="otherlist" @click="tabssearchct()">
            <img src="../assets/sousuo1.png" alt="" v-if="isScrolledvlaue">
            <img src="../assets/sousuo.png" alt="" v-else>
          </div>

          <div class="otherlist shoujia" @click="onnav">
            <img src="../assets/hanbao.png" alt="" v-if="iswhite == false && !isScrolledvlaue">
            <img src="../assets/shoujia1.png" alt="" v-if="iswhite == false && isScrolledvlaue">
            <CloseBold v-if="iswhite == true" color="#085cc2"/>
          </div>
          <!-- 移动端导航 -->
          <div class="phone-nav" v-if="iswhite">
            <ul>
              <li class="one" v-for="(list, index) in lists " :key="index">
                <!-- a标签不能带href，不然会刷新，刷新数据和没调用点击函数一样，此处踩坑了 -->
                <a class="p_title">
                  <span class="navleft" @click=phonetabsnav(index)>{{ list.title }}</span>
                  <span class="navright" @click="curshow(list, index)" v-if="list.next.length!=0">
                    <img src="../assets/加.png" alt="" v-if="!list.flag">
                    <img src="../assets/jianhao.png" alt="" style="width: 15px;height: 15px" v-else>
                  </span>
                </a>
                <transition name="fade-slide">
                  <ul class="secondlevel" v-if="list.flag">
                    <li v-for="sub in list.next" @click="secondphonetabsnav(sub)">
                      <a>{{ sub.title }}</a>
                    </li>
                  </ul>
                </transition>
              </li>
            </ul>


          </div>
        </div>
      </div>
    </div>
    <!-- 下拉菜单 -->

    <transition name="fade-slide">
      <div class="selectsearch" v-if="isfrom">
        <div class="searchform">
          <div class="formleft">
            <input placeholder="请输入搜索内容" type="text" v-model="searchvalue" class="searchinput"/>
            <Search class="icon" @click="tosearch()" />
          </div>
          <!--          <div class="icon" style="color: #b4b5bd">-->
          <!--            <Search style="width: 25px; height: 25px" />-->
          <!--          </div>-->
        </div>
      </div>
    </transition>

  </div>
  <soilimpro v-if="issoilimpro" @offfalse="offfalse"></soilimpro>
</template>

<script setup>

import {
  ref,
  defineEmits,
  reactive,
  defineProps,
  defineExpose,
  onMounted,
  computed,
  watch
} from "vue";

const emit = defineEmits(["tabsnav"]);
import {Search, CloseBold} from "@element-plus/icons-vue";
import {useRoute, useRouter} from 'vue-router';
import {useMainStore} from '@/store/index.js'
import {API} from "../../api/index";
// 接受来自 Pinia 的数据
const mainStore = useMainStore();
let indexdata = ref([])
// 监听 mainStore 的变化
watch(mainStore, (newVal) => {
  indexdata.value = newVal.data
  let data = indexdata.value.categorisation_list
  console.log(data);




});

onMounted(() => {
  API.getquanjing()
      .then((res) => {
        navItems.value = res.data.data.categorisation_list.map(item => ({
          ...item,
          hasDropdown: item.next.length > 0,
        }));
        let data = res.data.data.categorisation_list
        lists.value = data.map(item => ({
          ...item,
          flag: false,
        }));
      })
})

const router = useRouter()
const props = defineProps({
  isscrolled: Boolean,
});
let issoilimpro = ref(false)
let isScrolledvlaue = ref(false);

// 父组件调用子组件示例
function getisscroll() {
  isScrolledvlaue.value = localStorage.getItem("isscrolled");
  if (isScrolledvlaue.value !== null) {
    isScrolledvlaue.value = isScrolledvlaue.value === "true";
  }
}

// PC端导航栏
let navItems = ref([])
// 移动端导航栏
let lists = ref([])


let currentIndex = ref(0); // 初始选中的navbox索引
// pc切换导航栏
function navtabs(index) {
  console.log(index);

  currentIndex.value = index;

  if (index == 0) {
    router.push("/");

  } else if (index == 4) {
    router.push("/contactus");
  } else if (index == 5) {
    router.push("/aboutny");
  } else if (index == 2) {
    router.push("/InternetThings");
  } else if (index == 3) {
    router.push("/MilitaryCreation");
  }
}

// PC端二级导航栏切换
function secondnavtabs(it) {
  if (it.title == '土壤改良') {
    router.push("/Soilimprovement");
  }
  if (it.title == '智慧农业') {
    router.push("/SmartAgriculture");
  }
  if (it.title == '社会化服务') {
    router.push("/socializedservices");
  }
  if (it.title == '智慧果园') {
    router.push("/SmartOrchard");
  }
  if (it.title == '智慧灌溉') {
    router.push("/Smartirrigation");
  }
  if (it.title == '智慧农业') {
    router.push("/SmartAgriculture");
  }
  if (it.title == '云柏订单农业') {
    router.push("/pay2");
  }
  if (it.title == '云柏AIGC农业') {
    router.push("/pay");
  }
}

// 土壤改良子组件传递过来的事件
function offfalse() {
  issoilimpro.value = false
}

let isfrom = ref(false);
let iswhite = ref(false);
let iswhitebc = ref(false);

function tabssearchct() {
  iswhitebc.value = !iswhitebc.value
  isfrom.value = !isfrom.value;
  isScrolledvlaue.value = !isScrolledvlaue.value
}

//手机端导航栏列表点击事件
function onnav() {
  iswhite.value = !iswhite.value
  iswhitebc.value = !iswhitebc.value
}

// 移动端点击事件
function curshow(list, index) {
  //如果状态是点开的，那么再次点击就隐藏
  if (list.flag) {
    list.flag = false;
  }
  //若当前的是隐藏，则点击当前的显示，其余的隐藏
  else {
    for (let i = 0; i < lists.value.length; i++) {
      lists.value[i].flag = false;
    }
    list.flag = true;
  }

}

// 移动端一级导航栏点击事件
function phonetabsnav(index) {
  iswhite.value = false
  iswhitebc.value = false
  if (index == 0) {
    router.push("/");
  } else if (index == 4) {
    router.push("/contactus");
  } else if (index == 5) {
    router.push("/aboutny");
  } else if (index == 2) {
    router.push("/InternetThings");
  }
  else if (index == 3) {
    router.push("/MilitaryCreation");
  }
}

// 移动端二级导航栏点击事件
function secondphonetabsnav(sub) {
  console.log(sub.title);
  iswhite.value = false
  iswhitebc.value = false
  lists.value.forEach(r => {
    r.flag = false
  })
  if (sub.title == '土壤改良') {
    router.push("/Soilimprovement");
  }
  if (sub.title == '智慧农业') {
    router.push("/SmartAgriculture");
  }
  if (sub.title == '社会化服务') {
    router.push("/socializedservices");
  }
  if (sub.title == '智慧果园') {
    router.push("/SmartOrchard");
  }
  if (sub.title == '智慧灌溉') {
    router.push("/Smartirrigation");
  }
  if (sub.title == '智慧农业') {
    router.push("/SmartAgriculture");
  }
  if (sub.title == '云柏订单农业') {
    router.push("/pay2");
  }
  if (sub.title == '云柏AIGC农业') {
    router.push("/pay");
  }
}
let searchvalue = ref('')
function tosearch() {
  if (searchvalue.value == '社会化服务'){
    router.push('/socializedservices')
  }
  if (searchvalue.value == '土壤改良'){
    router.push('/Soilimprovement')
  }
  if (searchvalue.value == '智慧农业'){
    router.push('/SmartAgriculture')
  }
  if (searchvalue.value == '云柏订单农业'){
    router.push('/pay2')
  }
  if (searchvalue.value == '云柏AIGC农业'){
    router.push('/pay')
  }
  if (searchvalue.value == '云柏农业物联网应用案例'){
    router.push('/InternetThings')
  }
  if (searchvalue.value == '智慧果园'){
    router.push('/SmartOrchard')
  }
  if (searchvalue.value == '智慧灌溉'){
    router.push('/Smartirrigation')
  }
  if (searchvalue.value == '新时代军创智慧农业动态'){
    router.push('/MilitaryCreation')
  }
  if (searchvalue.value == '联系云柏智慧农业'){
    router.push('/contactus')
  }
  if (searchvalue.value == '关于云柏智慧农业'){
    router.push('/aboutny')
  }
}
defineExpose({
  getisscroll,
});
</script>

<style lang="scss" scoped>
.top {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 100%;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  z-index: 1000;
}

.top:hover {
  background-color: #fff;

  .navbox {
    color: #333;
    cursor: pointer;
  }
}

.topbox {
  //   padding: 0.625rem;
  width: 90%;

  box-sizing: border-box;
  height: 111px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  margin: 0 auto;

  .top_left {
    width: 241px;
    height: 52px;

    .img {
      width: 100%;
      height: 100%;
    }
  }

  .top_right {
    display: flex;
    align-items: center;
    gap: 53px;
    height: 100%;
  }

  .navlist {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0px;

    color: #fff;
  }
}

.navlist {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 2.3125rem;
}

.search {
  width: 1.375rem;
  height: 1.375rem;

  .img {
    width: 100%;
    height: 100%;
  }
}

.navlist {
  position: relative;
}

.dropdown {
  position: absolute;
  left: 0;
  top: 0;
}

.navbox {
  font-size: 1.125rem;
  font-weight: 400;
  color: #fff;
}

.navboxlist {
  position: relative;
  height: 100%;
}

// .navboxlist .navbox:hover {
//     font-size: 1.125rem;
//     font-weight: 400;
//     letter-spacing: 0px;
//     color: rgba(57, 147, 237, 1);

// }

.navboxlist:hover .drop-down {
  display: block;
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(57, 147, 237, 1);
}

.navboxlist:hover .navbox {
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(57, 147, 237, 1);
}

.drop-down {
  display: none;
  position: absolute;
  left: 0;
  top: 111px;
  z-index: 100;
  text-align: center;
  padding-top: 10px;
  background-color: #fff;
  // box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 0.625rem 1.25rem;
  box-sizing: border-box;

  transition: all 0.5s ease;

  .drop-down-list {
    color: #333;
    font-size: 15px;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  z-index: -1;
}

.navbox {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  height: 100%;
}

.scrolled {
  color: #000;
}

.active {
  font-size: 1.125rem;
  font-weight: 700;
}

.selectsearch {
  width: 25rem;
  height: 5vh;
  background-color: #f8f8f8;
  position: absolute;
  right: 6.6rem;
  top: 100%;
  z-index: 10;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);

  //.icon {
  //  width: 16px;
  //  height: 16px;
  //  position: absolute;
  //  right: 41px;
  //  top: 46%;
  //  transform: translateY(-50%);
  //}
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.2s ease-in;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  //max-height: 0;
  opacity: 0;
}

.fade-slide-enter-to,
.fade-slide-leave-from {
  //max-height: 100px;
  /* 你可以根据需要调整这个值 */
  opacity: 1;
}

.searchform {
  width: 100%;
  height: 100%;

  .formleft {
    width: 100%;
    //padding: 3% 0;
    margin: 0;
    background-color: #fff;
    color: #666;
    position: relative;

    .icon {
      position: absolute;
      right: 3%;
      top: auto;
      width: 25px;
      height: 25px;
    }

    .searchinput {
      width: 100%;
      height: 100%;
      border: none;
      padding-left: 15px;
      font-size: 1rem;
      box-sizing: border-box;
    }

    .searchinput:focus {
      outline: none;
      border: none;
    }
  }
}

.drop-down-list:hover {
  color: #4e8afb;
}

.topbox .otherbox {
  display: none;
}

.phone-nav {
  display: none;
}

.is-whitepc {
  background-color: #fff !important;
}

@media screen and (max-width: 1004px) {
  .selectsearch {
    width: 25rem;
    height: 100%;
    background-color: #f8f8f8;
    position: absolute;
    right: 6.6rem;
    top: 100%;
    z-index: 10;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  }
  .searchform {
    width: 100%;
    height: 5vh;

    .formleft {
      width: 100%;
      padding: 3% 0;
      margin: 0;
      background-color: #fff;
      color: #666;
      position: relative;

      .icon {
        position: absolute;
        right: 3%;
        top: auto;
        width: 50px;
        height: 50px;
      }

      .searchinput {
        width: 100%;
        height: 100%;
        border: 0;
        padding-left: 15px;
        font-size: 2rem;
        box-sizing: border-box;
      }

      .searchinput:focus {
        outline: none;
        border: none;
        box-shadow: 0 0 0 2px transparent;
        // border-bottom: 1.3px solid gainsboro;
      }
    }
  }
  .is-white {
    background-color: #fff !important;
  }

  .topbox .navlist {
    display: none;
  }

  .topbox .top_left {
    width: 9rem;
    height: 3rem;
  }

  .topbox .otherbox {
    display: block;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .otherbox {
    .otherlist {

      width: 45px;
      height: 45px;


      img {
        width: 100%;
        height: 100%;
        margin: 0 10px;
      }
    }

    .shoujia {
      width: 50px;
      height: 50px;
      margin: 0 10px;
    }
  }

  .top:hover {
    background-color: transparent;

    .navbox {
      color: #333;
      cursor: pointer;
    }
  }

  .phone-nav {
    display: block;
    width: 100%;
    height: calc(100vh - 111px);
    position: fixed;
    right: 0;
    top: 108px;
    background: #fff;
    z-index: 501;
    padding-top: 37px;
    box-sizing: border-box;

    ul {
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 0px;
      color: rgba(0, 0, 0, 1);
      text-align: left;

      .one {
        border-bottom: 1px solid #f3f3f3;
        padding: 25px 0;
        box-sizing: border-box;
        //margin: 10px 0;

        font-size: 1.8rem;

        .p_title {
          display: flex;
          padding: 0 15px;
          box-sizing: border-box;
          justify-content: space-between;

          .navright {
            width: 26px;
            height: 26px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .navleft {
            font-size: 1.5rem;
          }
        }

      }

      .secondlevel {
        background: #eaeaea;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;

        li {
          font-size: 1.5rem;
          display: block;
          color: #666;
          border-bottom: 1px solid #f3f3f3;
          padding: 1rem 0;
          box-sizing: border-box;
        }
      }
    }
  }

  .selectsearch {
    left: 0;

    width: 100%;
  }
}
</style>
