import axios from "axios";

// 使axios请求时携带cookie
// axios.defaults.withCredentials = true;

// 创建axios实例  自定义实例默认值
const service = axios.create({
    timeout: 30000, // 请求超时时间
});
// 添加request拦截器
service.interceptors.request.use(
    (config) => {
        if (localStorage.getItem("token")) {
            //判断token是否存在
            //  携带token的字段自定义
            config.headers["Admin-Token"] = localStorage.getItem("token");
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
// 添加respone响应器
service.interceptors.response.use(
    (response) => {
        let res = {};
        res.status = response.status;
        res.data = response.data;
        return res;
    },
    (error) => {
        if (error.response && error.response.status === 404) {
            console.log("请求错误， 请仔细查看请求");
            // 此处可以添加一个404页面
            // router.push('/errorFound')
        }
        return Promise.reject(error.response);
    }
);

export function get(url, params = {}) {
    params.t = new Date().getTime(); //get方法加一个时间参数,解决ie下可能缓存问题.
    return service({
        url: url,
        method: "get",
        headers: {
            "Content-Type": "application/json",
         
        },
        params,
    });
}

//封装post请求
export function post(url, data = {}) {
    //默认配置
    let sendObject = {
        url: url,
        method: "post",
        headers: {
           	'Content-Type': 'application/json'
        },
        data: data,
    };
    sendObject.data = JSON.stringify(data);
    return service(sendObject);
}

//封装put方法 (resfulAPI常用)
export function put(url, data = {}) {
    return service({
        url: url,
        method: "put",
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
    });
}

//删除方法(resfulAPI常用)
export function deletes(url, data) {
    return service({
        url: url,
        method: "delete",
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
    });
}

//不要忘记export
export { service };

