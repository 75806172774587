import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import countTo from 'vue3-count-to'
import ElementPlus from "element-plus";
import './views/js/rem.js'; //在main.js中引入rem.js
import "element-plus/dist/index.css"; // 引入css
import VueQrcode from 'vue-qrcode'
import soilimpro from './components/soilimpro.vue'
import { createPinia } from 'pinia'
const pinia = createPinia()





router.beforeEach((to, from, next) => {
 

    let keywordsMeta = document.querySelector('meta[name="keywords"]');
    if (!keywordsMeta) {
        keywordsMeta = document.createElement('meta');
        keywordsMeta.name = localStorage.getItem('website_seokeyword');
        document.head.appendChild(keywordsMeta);
        keywordsMeta.content = to.meta.keywords;
    }

    next(); // 确保调用 next() 方法以确保导航继续进行。
});
createApp(App).use(router).use(ElementPlus).use(countTo).use(pinia).component('soilimpro', soilimpro).component('vue-qrcode', VueQrcode).mount('#app')
